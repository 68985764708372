<style>
.CodeMirror-gutter.CodeMirror-linenumbers {
  border-right: 1px solid #555555;
}
</style>

<template>
  <modal name="B2B_CABECALHO" height="auto" width="90%" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">

      <div class="form">

        <div class="xrow">
          <div class="col">
            <div class="form-group">
              <label for="tipo">{{config ? config.desc : ''}}</label>
              <select class="form-control" v-model="tipo" id="tipo">
                <option v-for="(chave, valor) in config.opcoes" :value="valor">{{ chave }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="xrow" v-if="tipo === 'CUSTOM'">
          <div class="col">
            <div class="form-group">
              <label for="altura">Altura do cabeçalho (em px)</label>
              <input class="form-control" type="number" v-model="altura" id="altura"/>
            </div>
          </div>
        </div>

        <div class="xrow" v-if="tipo === 'CUSTOM'">
          <div class="col">
            <div class="form-group">
              <label>Código do cabeçalho</label>
            </div>
            <div class="codemirror">
              <codemirror v-model="conteudo" :options="code_options"></codemirror>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': false }" v-on:click="updateConfig(config)">
        Atualizar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import clone from "just-clone";
import { codemirror } from "vue-codemirror";
import CloseModal from "../../../components/CloseModal";

// theme css
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";

// language js
import "codemirror/addon/selection/active-line.js";
import "codemirror/mode/xml/xml.js";

// autoCloseTags
import "codemirror/addon/edit/closetag.js";

export default {
  name: "B2B_CABECALHO",
  props: ["update", "close", "config"],
  components: {
    codemirror,
    CloseModal,
  },
  data() {
    return {
      tipo: "NO_HEADER",
      conteudo: "",
      altura: 100,
      code_options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/html",
        lineWrapping: true,
        theme: "monokai",
        showCursorWhenSelecting: true,
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
      },
    };
  },
  methods: {
    updateConfig() {
      let config = clone(this.config);
      config.valor = {
        tipo: this.tipo,
        altura: this.tipo === "CUSTOM" ? this.altura : null,
        conteudo: this.tipo === "CUSTOM" ? this.conteudo : null,
      };
      this.update(config);
    },
  },
  mounted() {
    this.tipo = this.config.valor ? this.config.valor.tipo : "NO_HEADER";
    this.conteudo = this.config.valor ? this.config.valor.conteudo : "";
    this.altura = this.config.valor ? this.config.valor.altura : 100;
  },
};
</script>
